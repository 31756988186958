import {Box, CircularProgress} from "@mui/material"
import * as React from "react"
import {ReactNode} from "react"

export default function Loading(props: { message?: ReactNode }) {
  return <Box display="flex"
    justifyContent="center"
    flexGrow={1}
    alignItems="center">
    {props.message ?? <CircularProgress/>}
  </Box>
}
