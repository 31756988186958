import React from "react"
import ReactDOM from "react-dom/client"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {registerSW} from "virtual:pwa-register"
import AppLazy from "./AppLazy"
import Loading from "./shared/components/Loading"

registerSW({immediate: true})

const app = document.getElementById("app")

if (app !== null) {
  // Inject our app into the DOM
  ReactDOM.createRoot(app).render(
    <React.StrictMode>
      <React.Suspense fallback={<Loading/>}>
        <AppLazy/>
      </React.Suspense>
    </React.StrictMode>
  )
}
